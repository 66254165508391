<template>
    <div class="profile block"> 
        <div class="profile-picture big-profile-picture clear">
            <v-avatar size="150">
                <!-- <v-img  class="card-img" :src="userCredentials.profile.profile_picture ? userCredentials.profile.profile_picture : '/img/avatar.jpg' "></v-img> -->
                <v-img v-if="userCredentials.profile.profile_picture" class="card-img" :src="userCredentials.profile.profile_picture" :avatar="userCredentials.profile.firstname"></v-img>
				<avatar v-else class="card-img" :username="userCredentials.profile.firstname+' '+userCredentials.profile.lastname"></avatar>
							
                <div class="updateAvatar">
                    <input type="file" id="imageInput" hidden @change="handleImageChange">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 btn-primary" fab small absolute right bottom @click="editImage" v-on="on" dark>
                                <v-icon dark>{{svg.camera}}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('Profile.editProfileImg')}}</span>
                    </v-tooltip>
                </div> 
            </v-avatar>
        </div>
        <v-form @submit.prevent="updateProfileHandlerSubmit" ref="form" class="edit-profile-form">
            <v-container>
                <v-row>
                   
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                        :background-color="colors.brandGrayColor"
                        :label="$t('ProfileEdit.firstName')"
                        v-model="userCredentials.profile.firstname"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        append-icon="mdi-account-outline"  
                        :rules="nameRules"
                        filled

                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="6">
                        <v-text-field
                        v-model="userCredentials.profile.lastname"
                        :background-color="colors.brandGrayColor"
                        :label="$t('ProfileEdit.lastName')"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        filled
                        append-icon="mdi-account-outline"  
                        :rules="nameRules"

                        ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                        v-model="userCredentials.profile.email"
                        :background-color="colors.brandGrayColor"
                        :label="$t('signup.emailPlaceholder')"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        filled
                        append-icon="mdi-email-outline"
                        :rules="emailRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                        v-model="userCredentials.profile.mobile"
                        :background-color="colors.brandGrayColor"
                        :label="$t('signup.mobilePlaceholder')"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        filled
                        append-icon="mdi-phone-outline"
                        :rules="mobileRules"
                        
                        
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="6">
                        <v-text-field
                        v-model="userCredentials.profile.position"
                        :background-color="colors.brandGrayColor"
                        :label="$t('ProfileEdit.position')"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        filled
                        append-icon="mdi-account-outline"  
                        :rules="nameRules"
                        ></v-text-field>
                    </v-col> -->
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>{{$t('ProfileEdit.resetPassword')}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col cols="12" sm="12" >
                                    <v-text-field
                                    v-model="oldPasswprd"
                                    :append-icon="showPassword2 ? svg.visibility : svg.visibilityOff"
                                    :type="showPassword2 ? 'text' : 'password'"
                                    name="input-10-1"
                                    :background-color="colors.brandGrayColor"
                                    :label="$t('ProfileEdit.oldPassword')"
                                    :loading="loadingForm"
                                    @click:append="showPassword2 = !showPassword2"
                                    :color="colors.brandPTextColor"
                                    height="40"
                                    filled
                                    autocomplete="new-password"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" >
                                    <v-text-field
                                    v-model="newPasswprd"
                                    :append-icon="showPassword ? svg.visibility : svg.visibilityOff"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-10-1"
                                    :background-color="colors.brandGrayColor"
                                    :label="$t('ProfileEdit.newPassword')"
                                    :loading="loadingForm"
                                    @click:append="showPassword = !showPassword"
                                    :color="colors.brandPTextColor"
                                    height="40"
                                    filled
                                    :rules="passRules"
                                    autocomplete="new-password"
                                    ></v-text-field>
                                </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    
                    <!-- <v-col cols="12" sm="12" >
                        <v-text-field
                        v-model="userCredentials.profile.address"
                        :background-color="colors.brandGrayColor"
                        :label="$t('ProfileEdit.address')"
                        :loading="loadingForm"
                        :color="colors.brandPTextColor"
                        light
                        height="40"
                        filled
                        append-icon="mdi-account-outline"  
                        :rules="nameRules"

                        ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="12">
                        <v-textarea 
                        type="text"  
                        v-model="userCredentials.profile.bio" 
                        :loading="loadingForm" 
                        no-resize
                        :background-color="colors.brandGrayColor"
                        :label="$t('Profile.YourBio')"
                        :color="colors.brandPTextColor"
                        light
                        height="100"
                        filled
                        append-icon="mdi-account-card-details"
                        :rules="bioRules"

                        >
                        </v-textarea>
                    </v-col> -->

                    <v-col cols="12" sm="12">
                        <!-------------------------  FORM ERRORS  ------------------->
                        <div v-if="errors" class="subtitle1 text-center text-capitalize red--text">
                            <p v-for="(errorMessage, i) in errors" :key="i">{{errorMessage}}</p>
                        </div>
                        <!-------------------------  END FORM ERRORS ------------------->
                    </v-col>

                    <v-col cols="12" sm="12">
                        <div class="mt-5">
                            <v-btn type="submit" large :loading="loadingForm" class="updateBtn" :color="colors.brandPrimColor" elevation="0" dark>
                                {{$t('ProfileEdit.submitBtn')}}
                            </v-btn>
                        </div>
                    </v-col>

                </v-row>
            </v-container>
        </v-form>
        
    </div>

</template>


<script>
// MIXINS
import { reset } from '@/mixins/mixins';
import i18n from '@/plugins/i18n';

//Avatar component
import Avatar from 'vue-avatar'

// SVG ICONS
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraRetakeOutline  } from '@mdi/js';

//VUEX
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
    mixins: [reset],
    components: {
            Avatar
    },
     data() {
        return {
            showPassword: false,
            showPassword2: false,
            oldPasswprd:'',
            newPasswprd:'',
            nameRules:[
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqUserName,
            ],
            emailRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqEmail,
                v => /.+@.+\..+/.test(v) || i18n.messages[this.locale].errorsList.signup.errors.emailMustValid,
            ],
            mobileRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqMobile,
                v => /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(v) || i18n.messages[this.locale].errorsList.signup.errors.mobileMustValid,
            ],
            passRules: [
                // v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqPass,
                v => (v && v.length >= 6 || v.length == 0) || i18n.t('errorsList.signup.errors.passMoreThanNChars', {'n': '6'}),
            ],
            // bioRules: [
            //     v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqBio,
            //     v => (v && v.length >= 10) || i18n.t('errorsList.signup.errors.bioMoreThanNChars', {'n': '10'}),
            // ],
            svg: {
                visibility: mdiEyeOutline,
                visibilityOff: mdiEyeOffOutline,
                camera: mdiCameraRetakeOutline,

            },
            
        }
    },
    methods: {
        updateProfileHandlerSubmit() {
            let LocalOrgSlug = process.env.VUE_APP_ORG_SLUG;
            let full = window.location.host;
            let parts = full.split('.');
            let subDomain = parts[0];

            if (this.$refs.form.validate()) {
                this.$store.dispatch('EDIT_USER_DETAILS', {
                    "locale":this.locale,
                    "organization": LocalOrgSlug ? LocalOrgSlug : subDomain,
                    "firstname": this.userCredentials.profile.firstname,
                    // "lastname": this.userCredentials.profile.lastname,
                    "mobile":this.userCredentials.profile.mobile,
                    "email":this.userCredentials.profile.email,
                    "position":this.userCredentials.profile.position,
                    // "address":this.userCredentials.profile.address,
                    "password": this.newPasswprd,
                    "old_password": this.oldPasswprd,
                    // "bio":this.userCredentials.profile.bio,
                })
                .then(() => {
                    this.$router.push({name: 'profile'});
                })
            }
        },
        handleImageChange(event) {
           // const image = event.target.files[0];
            var f = event.target.files[0]; // FileList object
            var reader = new FileReader();
            var store =this.$store
            // Closure to capture the file information.
            reader.onload = (function(theFile) {
                return function(e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //Update the image
                const formData = {
                    "image":base64String
                };
                //Upload the image
                store.dispatch('UPLOAD_IMAGE', formData);
                };
            })(f);
            // Read in the image file as a data URL.
            reader.readAsBinaryString(f);
        },
        editImage() {
            const imageInput = document.getElementById('imageInput');
            imageInput.click();
        }
    },
    computed: {
        ...mapGetters(['colors', 'errors', 'loadingForm', 'theme', 'locale', 'userCredentials']),
    
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
          
    },
    mounted() {
      
    },
}
</script>
<style lang="scss" scoped>
    .vue-avatar--wrapper{
	    width: 150px !important;
    height: 150px !important;
	font-size: 3em !important;
        border: 5px solid white;
	span{
		font-size:3em;
	}
}
    fieldset {
        display: none;
    }

    .edit-profile-form {
        background: #9dc3e8;
        padding: 100px 15px 15px;
        border-radius: 10px;
        text-align: center;
    }

    .col-sm-12.col-12 {
        padding: 0 8px !important;
    }

    .profile-picture {
        text-align: center;
        margin-bottom: -50px;

    }

    .v-responsive.v-image.card-img {
        border: 5px solid white;
    }
    .v-size--large{
    min-width: 135px !important;
  }

//   .updateBtn {
//       color: var(--brandPrimColor);
//       background: var(--brandSecColor);
//   }

</style>
<template>
  <v-container >
        <v-row justify="center" class="ProfileEdit">
            <v-col sm="10" md="6" >
                <h2 class="mb-5" >{{$t('ProfileEdit.title')}}</h2>
                <AppProfileEditForm></AppProfileEditForm>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script>
// import i18n from '@/plugins/i18n';


// COMPONENTS
import AppProfileEditForm from '@/components/Form/AppProfileEditForm';


export default {
    components: {
        AppProfileEditForm
    }
}
</script>

<style lang="scss" scoped>
  .ProfileEdit h2 {
    text-align: center;
    color: #394263 ;
  }
  
</style>